import { computed } from '@angular/core';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';

type NavAnchorState = {
  anchor: string;
  intersect: IntersectionObserverEntry;
};

export const NavAnchorIntersectionStore = signalStore(
  { providedIn: 'root' },
  withState({
    _anchors: [] as NavAnchorState[],
  }),
  withMethods((store) => {
    const remove = (anchor: string) => {
      const anchors = store._anchors().filter((a) => a.anchor !== anchor);
      patchState(store, { _anchors: anchors });
    };
    const add = (anchor: string, intersect: IntersectionObserverEntry) => {
      remove(anchor);
      console.log('adding', `'${anchor}'`);
      const anchors = [...store._anchors(), { anchor, intersect }].sort((a, b) => {
        const aTop = a.intersect.boundingClientRect.top;
        const bTop = b.intersect.boundingClientRect.top;
        const diff = aTop - bTop;
        console.log('a', aTop, 'b', bTop, diff);
        return diff;
      });
      patchState(store, { _anchors: anchors });
      console.log('added', anchors);
    };

    return {
      add,
      remove,
    };
  }),
  withComputed(({ _anchors }) => {
    const anchor = computed(() => _anchors().find((a) => a.intersect.isIntersecting)?.anchor ?? '');
    const intersectingAnchors = computed(() => _anchors().filter((a) => a.intersect.isIntersecting));
    const firstAnchor = computed(() => _anchors()[0]?.anchor ?? '');
    const lastAnchor = computed(() => _anchors()[intersectingAnchors().length - 1]?.anchor ?? '');
    const firstIntersectingAnchor = computed(() => intersectingAnchors()[0]?.anchor ?? '');
    const lastIntersectingAnchor = computed(() => intersectingAnchors()[intersectingAnchors().length - 1]?.anchor ?? '');
    return {
      anchor,
      firstAnchor,
      lastAnchor,
      firstIntersectingAnchor,
      lastIntersectingAnchor,
    };
  }),
);
