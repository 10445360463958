import { convertNavItem } from '../base/convertNavItem';
import { evalItemProp } from '../util/evalItemProp';
import { AnchorNavItem, AnchorNavItemData } from './AnchorNavItem';

export function convertAnchorNavItem(item: AnchorNavItem): AnchorNavItemData {
  return {
    ...convertNavItem(item),
    type: item.type,
    relativeTo: item.relativeTo,
    anchor: evalItemProp(item.anchor),
  };
}
