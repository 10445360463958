<nav>
  @for (group of groups(); track $index) {
    <div class="nav-group" @fade>
      @if (group.heading) {
        <span class="heading">{{ group.heading }}</span>
      }
      <div class="nav-group-items">
        @for (item of group.items; track item.id) {
          @switch (item.type) {
            @case (NavItemType.Button) {
              @if (cast(item, NavItemType.Button); as item) {
                <button
                  ideal-navigation-list-item
                  [label]="item.label()"
                  [icon]="item.icon()"
                  (click)="item.onClick($event)"
                  [disabled]="item.disabled()"
                  [active]="item.active()"
                  [menu]="item.hasChildren?.() || false"
                ></button>
              }
            }
            @case (NavItemType.ExternalLink) {
              @if (cast(item, NavItemType.ExternalLink); as item) {
                <a
                  ideal-navigation-list-item
                  [label]="item.label()"
                  [icon]="item.icon()"
                  [href]="item.url()"
                  [target]="item.target() ?? '_blank'"
                  [disabled]="item.disabled()"
                  [menu]="item.hasChildren?.() || false"
                ></a>
              }
            }
            @case (NavItemType.RouteButton) {
              @if (cast(item, NavItemType.RouteButton); as item) {
                <button
                  ideal-navigation-list-item
                  [label]="item.label()"
                  [icon]="item.icon()"
                  [routerLink]="item.command()"
                  [queryParams]="item.args()?.queryParams"
                  [fragment]="item.args()?.fragment"
                  [queryParamsHandling]="item.args()?.queryParamsHandling"
                  [state]="item.args()?.state"
                  [info]="item.args()?.info"
                  [relativeTo]="item.args()?.relativeTo"
                  [preserveFragment]="item.args()?.preserveFragment"
                  [skipLocationChange]="item.args()?.skipLocationChange"
                  [replaceUrl]="item.args()?.replaceUrl"
                  [disabled]="item.disabled()"
                  [menu]="item.hasChildren?.() || false"
                  routerLinkActive
                  [routerLinkActiveOptions]="
                    item.linkOptions() ?? { matrixParams: 'exact', queryParams: 'exact', paths: 'exact', fragment: 'exact' }
                  "
                ></button>
              }
            }
            @case (NavItemType.RouteLink) {
              @if (cast(item, NavItemType.RouteLink); as item) {
                <a
                  ideal-navigation-list-item
                  [label]="item.label()"
                  [icon]="item.icon()"
                  [routerLink]="item.command()"
                  [queryParams]="item.args()?.queryParams"
                  [fragment]="item.args()?.fragment"
                  [queryParamsHandling]="item.args()?.queryParamsHandling"
                  [state]="item.args()?.state"
                  [info]="item.args()?.info"
                  [relativeTo]="item.args()?.relativeTo"
                  [preserveFragment]="item.args()?.preserveFragment"
                  [skipLocationChange]="item.args()?.skipLocationChange"
                  [replaceUrl]="item.args()?.replaceUrl"
                  [target]="item.target() || '_self'"
                  [disabled]="item.disabled()"
                  [menu]="item.hasChildren?.() || false"
                  routerLinkActive
                  [routerLinkActiveOptions]="
                    item.linkOptions() ?? { matrixParams: 'exact', queryParams: 'exact', paths: 'exact', fragment: 'exact' }
                  "
                ></a>
              }
            }
            @case (NavItemType.Anchor) {
              @if (cast(item, NavItemType.Anchor); as item) {
                <a
                  ideal-navigation-list-item
                  [label]="item.label()"
                  [icon]="item.icon()"
                  [routerLink]="['.']"
                  [relativeTo]="item.relativeTo"
                  [fragment]="item.anchor() || undefined"
                  [queryParamsHandling]="'preserve'"
                  [disabled]="item.disabled()"
                  [menu]="item.hasChildren?.() || false"
                  routerLinkActive
                  [routerLinkActiveOptions]="{ matrixParams: 'exact', queryParams: 'exact', paths: 'exact', fragment: 'exact' }"
                ></a>
              }
            }
          }
        }
      </div>
    </div>
  }
</nav>
