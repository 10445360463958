import { Signal } from '@angular/core';
import { NavItemDataProperty } from '../NavItemDataProperty';
import { NavItemType } from '../NavItemType';
import { NavItem, NavItemData, isNavItem } from '../base/NavItem';
import { ActivatedRoute } from '@angular/router';

export interface AnchorNavItemData extends NavItemData {
  type: NavItemType.Anchor;
  relativeTo: ActivatedRoute;
  anchor: Signal<string | undefined>;
}

export interface AnchorNavItem extends NavItem {
  type: NavItemType.Anchor;
  relativeTo: ActivatedRoute;
  anchor?: NavItemDataProperty<string>;
}

export function isAnchorNavItem(item: NavItem): item is AnchorNavItem {
  return isNavItem(item) && item.type === NavItemType.Anchor;
}
