import { CdkScrollable } from '@angular/cdk/scrolling';
import { ApplicationRef, computed, Directive, effect, ElementRef, inject, input } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { elementIntersection } from 'common-util';
import { ScrollNavigationAnchor, ScrollNavigationService } from 'navigation-data';
import { distinctUntilChanged, filter, map } from 'rxjs';

@Directive({
  selector: '[idealNavAnchor]',
  standalone: true,
})
export class NavAnchorDirective implements ScrollNavigationAnchor {
  private readonly _scrollNavigationService = inject(ScrollNavigationService);

  public readonly container = inject(CdkScrollable);
  public readonly relativeTo = inject(ActivatedRoute);

  private readonly _elmRef = inject<ElementRef<HTMLElement>>(ElementRef);
  public get element() {
    return this._elmRef.nativeElement;
  }
  private _anchorStore = this._scrollNavigationService.getAnchorStore(this.container);

  public readonly anchor = input<string | undefined>(undefined, { alias: 'idealNavAnchor' });
  public readonly navId = input<string>();
  public readonly navLabel = input<string>('');
  public readonly navDisabled = input<boolean>(false);
  public readonly navHeading = input<string>('');
  public readonly navHidden = input<boolean>(false);
  public readonly navPriority = input<number>();
  public readonly navIcon = input<string>();
  public readonly id = computed(() => {
    const anchor = this.anchor();
    if (!anchor) {
      return undefined;
    }
    const navId = this.navId();
    return navId ? `${navId}/${anchor}` : anchor;
  });

  public readonly intersects = toSignal(
    elementIntersection(this.element, {
      root: this.container.getElementRef().nativeElement,
      threshold: 0,
      rootMargin: '0px 0px -75% 0px',
    }).pipe(
      map((v) => v.isIntersecting),
      distinctUntilChanged(),
      takeUntilDestroyed(),
    ),
    { initialValue: false },
  );

  constructor() {
    effect((clean) => {
      if (!this.id()) {
        return;
      }
      this._scrollNavigationService.anchorCreated(this);
      clean(() => {
        this._scrollNavigationService.anchorDestroyed(this);
      });
    });
    effect(() => {
      if (!this.id()) {
        return;
      }
      this._anchorStore.setAnchorIntersecting(this, this.intersects());
    });
  }
}
