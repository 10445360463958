import { NavItemType } from '../NavItemType';
import { convertAnchorNavItem } from '../anchor/convertAnchorNavItem';
import { convertButtonNavItem } from '../button/convertButtonNavItem';
import { convertExternalLinkNavItem } from '../external-link/convertExternalLinkNavItem';
import { convertRouteButtonNavItem } from '../route-button/convertRouteButtonNavItem';
import { convertRouteLinkNavItem } from '../route-link/convertRouteLinkNavItem';

export const NavItemConverters = {
  [NavItemType.Button]: convertButtonNavItem,
  [NavItemType.ExternalLink]: convertExternalLinkNavItem,
  [NavItemType.RouteButton]: convertRouteButtonNavItem,
  [NavItemType.RouteLink]: convertRouteLinkNavItem,
  [NavItemType.Anchor]: convertAnchorNavItem,
};
