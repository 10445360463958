import { CdkScrollable } from '@angular/cdk/scrolling';
import { inject, ApplicationRef, signal, untracked, effect, isSignal, computed } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router, Scroll } from '@angular/router';
import { filter, distinctUntilChanged, debounceTime, first } from 'rxjs';
import { NavigationStore } from '../store';
import { AnchorNavItem, NavItemType } from '../types';
import { ScrollNavigationAnchor } from './ScrollNavigationAnchor';

const compareAnchors = (a: ScrollNavigationAnchor, b: ScrollNavigationAnchor) => {
  const aVal = a.element.getBoundingClientRect().bottom;
  const bVal = b.element.getBoundingClientRect().bottom;
  return aVal - bVal;
};

export class ScrollNavigationManager {
  private readonly _navStore = inject(NavigationStore);
  private readonly _router = inject(Router);
  private readonly _applicationRef = inject(ApplicationRef);
  private readonly _isStable$ = this._applicationRef.isStable.pipe(
    filter((v) => v),
    distinctUntilChanged(),
    takeUntilDestroyed(),
  );
  private readonly _anchors: Map<string, ScrollNavigationAnchor> = new Map();
  private readonly _anchorHashToId: Map<string, string> = new Map();
  private readonly _anchorIntersecting: Map<string, boolean> = new Map();
  private _anchorIds: string[] = [];
  private _anchorIntersectingIds: string[] = [];
  private readonly _sortedKey = signal<string>('');
  private readonly _sortedIntersectKey = signal<string>('');

  private _ignoreScroll = signal(true);
  private _ignoreNav = signal(false);

  private ignoreScroll(force: boolean = false) {
    if (force || !untracked(this._ignoreScroll)) {
      this._ignoreScroll.set(true);
      this.container
        .elementScrolled()
        .pipe(debounceTime(100), first())
        .subscribe(() => {
          this._ignoreScroll.set(false);
        });
    }
  }

  private ignoreNav(force: boolean = false) {
    if (force || !untracked(this._ignoreNav)) {
      this._ignoreNav.set(true);
      this.container
        .elementScrolled()
        .pipe(debounceTime(100), first())
        .subscribe(() => {
          this._ignoreNav.set(false);
        });
    }
  }

  constructor(public readonly container: CdkScrollable) {
    this._router.events.pipe(filter((s) => s instanceof Scroll)).subscribe((e) => {
      if (!this._ignoreNav()) {
        setTimeout(() => {
          this.scrollToHash();
        }, 100);
      }
    });
    this._isStable$.subscribe(() => {
      this.scrollToHash();
    });
    effect(() => {
      this._sortedIntersectKey();
      const ignoreScroll = untracked(this._ignoreScroll);
      if (!ignoreScroll) {
        const navItem = this._navStore.getById(this._anchorIntersectingIds[0]) as AnchorNavItem;
        if (navItem) {
          const hidden = isSignal(navItem.hidden) ? untracked(navItem.hidden) : false;
          const disabled = isSignal(navItem.disabled) ? untracked(navItem.disabled) : false;
          const ank = isSignal(navItem.anchor) ? untracked(navItem.anchor) : undefined;
          if (!hidden && !disabled && ank !== undefined) {
            if (location.hash !== ank) {
              this.ignoreNav(true);
              location.hash = ank;
            }
          }
        }
      }
    });
  }

  private scrollToHash() {
    const hash = location.hash.replace('#', '');
    console.log('scrollToHash', `'${hash}'`);
    this.ignoreScroll(true);
    if (hash === '') {
      this.container.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      const ankId = this._anchorHashToId.get(hash) ?? this._anchorHashToId.get('') ?? '';
      const ank = this._anchors.get(ankId);
      ank?.element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  private findId(anchor: ScrollNavigationAnchor) {
    return this._anchors.entries().find(([_, a]) => a === anchor)?.[0];
  }

  public addAnchor(anchorInst: ScrollNavigationAnchor) {
    const id = untracked(anchorInst.id)!;
    const existingId = this.findId(anchorInst);
    if (existingId !== undefined && existingId !== id) {
      this.removeAnchor(anchorInst);
    }
    if (existingId !== id) {
      this._anchors.set(id, anchorInst);
      this._anchorIds.push(id);
      this._anchorIntersecting.set(id, anchorInst.intersects());

      const anchor = id.split('/').pop()!;
      this._anchorHashToId.set(anchor, id);

      const orderedPriority = computed(() => {
        const key = this._sortedKey();
        if (anchor === '') {
          return Number.MAX_SAFE_INTEGER;
        }
        const ids = [...this._anchorIds].reverse();
        return ids.indexOf(id);
      });

      this._navStore.remove(id);
      this._navStore.add({
        id,
        type: NavItemType.Anchor,
        label: anchorInst.navLabel,
        relativeTo: anchorInst.relativeTo,
        anchor: signal(anchor),
        disabled: anchorInst.navDisabled,
        heading: anchorInst.navHeading,
        hidden: anchorInst.navHidden,
        icon: anchorInst.navIcon,
        priority: computed(() => anchorInst.navPriority() ?? orderedPriority()),
      } as AnchorNavItem);

      this.sortAnchors();
    }
  }

  public removeAnchor(anchor: ScrollNavigationAnchor) {
    const id = this.findId(anchor);
    if (id !== undefined) {
      this._anchors.delete(id);
      this._anchorIds = this._anchorIds.filter((i) => i !== id);
      this._navStore.remove(id);
      this._anchorIntersecting.delete(id);
      this._anchorHashToId.delete(id.split('/').pop()!);
      this.sortAnchors();
    }
  }

  public setAnchorIntersecting(anchor: ScrollNavigationAnchor, intersect: boolean) {
    const id = this.findId(anchor);
    if (id !== undefined) {
      this._anchorIntersecting.set(id, intersect);
      this.sortAnchors();
    }
  }

  private sortAnchors() {
    this._anchorIds.sort((a, b) => compareAnchors(this._anchors.get(a)!, this._anchors.get(b)!));
    const key = this._anchorIds.join('|');
    this._sortedKey.set(key);
    this._anchorIntersectingIds = this._anchorIds.filter((id) => !!this._anchorIntersecting.get(id));
    this._sortedIntersectKey.set(this._anchorIntersectingIds.join('|'));
  }
}
