import { CdkScrollable } from '@angular/cdk/scrolling';
import { inject, Injectable, Injector, runInInjectionContext, untracked } from '@angular/core';
import { ScrollNavigationAnchor } from './ScrollNavigationAnchor';
import { ScrollNavigationManager } from './ScrollNavigationManager';

@Injectable({
  providedIn: 'root',
})
export class ScrollNavigationService {
  private _injector = inject(Injector);
  private _stores: Map<CdkScrollable, ScrollNavigationManager> = new Map();

  public anchorCreated(anchor: ScrollNavigationAnchor) {
    const store = this.getAnchorStore(anchor.container);
    store.addAnchor(anchor);
    return store;
  }

  public getAnchorStore(container: CdkScrollable) {
    return runInInjectionContext(this._injector, () => {
      if (!this._stores.has(container)) {
        const store = new ScrollNavigationManager(container);
        this._stores.set(container, store);
      }
      return this._stores.get(container)!;
    });
  }

  public anchorDestroyed(anchor: ScrollNavigationAnchor): void {
    if (!this._stores.has(anchor.container) || !untracked(anchor.id)) {
      return;
    }
    const store = this._stores.get(anchor.container)!;
    store.removeAnchor(anchor);
  }
}
